import { BiShoppingBag, BiSolidCar, BiSolidCart, BiSolidTrash, BiWallet } from "react-icons/bi";
import { Button, Container, Input, SelectPicker } from "rsuite";
import countryList from 'react-select-country-list'
import { useEffect, useMemo, useState } from "react";
import Select from "react-select";
import axios from "axios";
import { BsFillBasket3Fill } from "react-icons/bs";
import Skeleton from "react-loading-skeleton";
import { MdVerified } from "react-icons/md";
import { useNavigate } from "react-router-dom";
 
export const Checkout = () => {
    const [selectedCountry, setSelectedCountry] = useState('');
    const [totalPrice, setTotalPrice] = useState(0);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [storedCartItems, setCartItems] = useState({});
    const navigate = useNavigate(); 
    useEffect(() => {

            
        // Retrieve cartItems from session storage
        const storedCartItems = sessionStorage.getItem('cartItems');

        if (storedCartItems) {
            setCartItems(JSON.parse(storedCartItems));
        }
        console.log(storedCartItems);
        
        // Uncomment the following lines to retrieve cartItems from cookies
        // const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)cartItems\s*\=\s*([^;]*).*$)|^.*$/, "$1");
        // if (cookieValue) {
        //     setCartItems(JSON.parse(cookieValue));
        // }
      }, []); // Empty dependency array ensures the effect runs only once after the initial render

     
    useEffect(() => {
        // Calculate the total price
        if(storedCartItems.length > 0){
            const totalPrice = storedCartItems.reduce((acc, item) => acc + item.priced, 0);
            setTotalPrice(totalPrice);
        }
    }, [storedCartItems]);

    const delItemFromCart = (_id) => {
        const storedCartItems = JSON.parse(sessionStorage.getItem('cartItems')) || [];
        const filteredArray = storedCartItems.filter(item => item.id !== _id);
        setCartItems(filteredArray);
        sessionStorage.setItem('cartItems', JSON.stringify(filteredArray));
    };
    
    
    const validateEmail = () => {
        // Regular expression to validate email
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleChange = (value, name) => {
        if (name === 'firstName') {
            setFirstName(value);
        } else if (name === 'lastName') {
            setLastName(value);
        } else if (name === 'email') {
            setEmail(value);
        }
    };

    function CountrySelector() {
        const options = useMemo(() => countryList().getData(), [])
      
        const changeHandler = value => {
            setSelectedCountry(value)
        }
        const style = {
            control: base => ({
              ...base,
              border: 0,
              // This line disable the blue border
              boxShadow: 'none'
            })
          };
        return <Select className="cselect text-white " options={options} value={selectedCountry} styles={style} onChange={changeHandler} id="input-country" />
      }
    

    const submitData = async () => {
        const errors = {};

        if (!firstName.trim()) {
            errors.firstName = true;
        }
        if (!lastName.trim()) {
            errors.lastName = true;
        }
        if (!email.trim() || !validateEmail()) {
            errors.email = true;
        }

        if (Object.keys(errors).length > 0) {
            setErrors(errors);
            return;
        }
    

        try {
            // Extract only the IDs from the array of objects
            const result = storedCartItems.map(item => ({
                "id": item.id,
                "version": item.version
              }));
              

            axios.post('http://localhost/inapi/index.php', {
        //   axios.post('https://www.api.initialsounds.com/index.php', {

                method: "order",
                status: "initiated",
                firstname: document.getElementById('input-firstname').value,
                lastname: document.getElementById('input-lastname').value,
                email: document.getElementById('input-email').value,
                country: selectedCountry.label,
                company: document.getElementById('input-company').value,
                VAT: document.getElementById('input-vat').value,
                products: JSON.stringify(result)

              
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                //success
                // console.log(response.data.url);
                if(response.data.url !== "undefined" && response.data.url !== undefined)
                    window.location.href = response.data.url;
            })
            .catch(error => {
                console.error('Error making POST request:', error);
            }) 
            
            // Reset form and state if submission is successful
            setFirstName('');
            setLastName('');
            setEmail('');
            setSelectedCountry('');
            setErrors({});
        } catch (error) {
            console.error('Error making POST request:', error);
        }
    }
    
    return(
        <div className="mt-40 mb-20 md:block flex md:flex-nowrap flex-wrap justify-center ">

        <div className="  flex   justify-center items-center ">
            <div className="md:w-[40%] md:max-w-[800px]">
            <h3 className="text-white text-center flex gap-2 justify-center items-center cc"><BsFillBasket3Fill />  </h3>
            <h3 className="text-white text-center flex gap-2 justify-center items-center w-full font-light">Cart Items  </h3>
            <br/>
            <div className=" overflow-x-hidden w-full h-full   my-4   mb-10">
            
            
            {storedCartItems.length > 0 && storedCartItems.map((item, index) => (
          

                        <div
                                key={index}
                                className="track-item flex gap-4 justify-between items-center w-full"
                            >
                                <div className="w-full track-wrapper-list flex justify-center items-center gap-3">
                                <div className=" image-wrapper max-w-[90px]">
                                
                                {item.img ? (
                                    <img
                                    className="min-h-[90px]"
                                    src={`http://${item.location + item.id}/` + "img" + ".jpg"}

                                    alt={item.title + " Image"}
                                    />
                                    ) : (
                                    <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={70} height={70}  />

                                    )}

                               
                                </div>

                                <div className="track-info pb-2 pt-2 pl-1 w-full   min-w-[200px]">
                                    {item.author ? (
                                    <a
                                        className="author pointer-events-none"
                                        href={"/profile/" + item.authorUrl}
                                    >
                                        <span className="flex justify-start items-center gap-1">
                                        <span className="citm text-blue-400"><MdVerified /></span> {item.author}
                                        </span>
                                        
                                    </a>
                                    ) : (
                                    <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={70} />

                                    )}

                                

                                    <a
                                    className="title pointer-events-none"
                                    href={"/view-track/" + item.url + "/" + item.id}
                                    >
                                    <span className="title">{item.title}</span>
                                    
                                    </a>
                                    {item.priced >= 0 ? (
                                    <div className="md:flex flex-wrap gap-1 ">
                                    <span className="price ">
                                        {item.currency + item.priced}
                                    </span>

                                    <span className="bpm "> - {item.bpm}  </span>  <span className="bpm"> {item.songkey}</span>
                                        <div className="mobile-tags md:hidden" >
                                            {item.tags && item.tags.length >=  0 ? (
                                            item.tags.map((tag, tagIndex) => (
                                                <Button className="button tagbtn mr-1 flex justify-start items-center">
                                                <span className="hashtag mr-2"># </span>
                                                <span className="tag-meta"> {tag}</span>
                                                </Button>
                                            ))
                                            ) : (
                                            
                                            Array.from({ length: 3 }).map((_, index) => (
                                                <Skeleton className="mx-2"  baseColor="#151515" highlightColor="#1a1a1a" count={1} width={80} height={40} />
                                            ))
                                            )}
                                        </div>
                              
                                    </div>
                                    
                                    ) : (
                                    <div className="flex">
                                            <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={200} />

                                    </div>
                                    
                                    )}
                                             
                                </div>
                                
                                <div className=" ">
                                    
                                    <Button className="del-button  button bg-transparent tagbtn mr-1 flex justify-start items-center" id="del-button" onClick={() => delItemFromCart(item.id)} >
                                           <BiSolidTrash />
                                    </Button>
                                    </div>
                                </div>
                                <div className="flex justify-between items-center"></div>
                            
                            
                            
                            </div>
                
                ))}
                </div>
            </div>
            </div>
                
            <div className=" mt-10"></div>
            {storedCartItems.length > 0  ?  (
                    <> 
            <Container className="r md:p-0 p-5   max-w-[1200px] flex justify-center items-center">
                <h4 className="text-white font-light"><span className="flex justify-center items-center gap-2"> Checkout <BiShoppingBag /> </span> </h4>
                
                <form className="md:w-[65%] md:min-w-[800px] w-[97%]">
                
                    <div className="flex justify-start items-center w-full mb-2 mt-10">

                        <h5 className="text-white font-light">Billing details:  </h5>
                    </div>
                    <div className=" flex gap-3 ">
                        <Input
                        name="firstname"
                        id="input-firstname"
                        placeholder="First name" 
            
                        onChange={value => handleChange(value, 'firstName')}
                        className={`input w-[50%]" ${errors.firstName ? 'error-input' : ''}`}
                        />
                        <Input
                            name="lastname"
                            id="input-lastname"
                            placeholder="Last Name" 
                            onChange={value => handleChange(value, 'lastName')}
                            className={`input w-[50%]  ${errors.lastName ? 'error-input' : ''}`}
                            />
                    </div>
                    <Input
                    name="email"
                    id="input-email"
                    placeholder="Enter your e-mail address" 
                   
                    onChange={value => handleChange(value, 'email')}
                    className={`input  w-[50%] ${errors.email ? 'error-input' : ''}`}
                    />
                    <CountrySelector />

                    <br />

                    <div className="flex justify-start items-center w-full  mt-10">
                        <h5 className="text-white font-light">Optional:  </h5>
                    </div>

                        <div className="flex justify-center items-center mt-2 mb-10">
                            <div className="w-full">
                                <div className=" md:flex gap-3 ">
                                <Input
                                    name="company"
                                    id="input-company"
                                    placeholder="Company Name (optional)" 
                                    className="input  w-[50%]"
                                />
                                <Input
                                    name="vat"
                                    id="input-vat"
                                    placeholder="VAT Number" 
                                    className="input w-[50%]"
                                />
                            </div>
                        </div>
                    </div>
                    
                
                        <div className="flex justify-between gap-2 w-[100%] items-center mt-40">
                        <h5 className="text-white font-light text-start">YOUR TOTAL: </h5>
                            <div className="mb-16 ">
                                <div className="flex justify-center items-end mb-5 " >
                                        <Input
                                    name="coupon"
                                    id="input-coupon"
                                    placeholder="Coupon code" 
                                    className={"input !w-[130px] "}
                                    onChange={value => handleChange(value, 'email')}
                                    />
                                    <Button className="btn btn-main" appearance="secondary">Activate</Button>
                                </div>
                            <h5 className="text-white text-end font-light text-start">€ {totalPrice} </h5>
                        </div>
                        </div>
                        <hr />
                    
                    <Button className="cartbtn placeorder button w-full fixed flex gap-1 justify-center items-center " onClick={submitData}>
                        Place Order <BiWallet />
                    </Button>
                 
                </form>
                
            </Container>
            </>

) : (<>
    <p className="text-center text-white">
        Your cart is empty!
    </p>

</>) }
    
                        <div className="mb-10"></div>
        </div>
        
    );
}