import React, { useState, useEffect, Component, useRef } from "react";
import $ from "jquery";
import { RxHamburgerMenu } from "react-icons/rx";
import { BsFillBasket3Fill } from "react-icons/bs";
import { IoIosClose } from 'react-icons/io';
import { Cart } from "./ShoppingCart";
import { Helmet } from "react-helmet";

export const Header = ({cartOpen, setCartOpen, cartItems, setCartItems}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const cartRef = useRef(null);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const [isOpen, setIsOpen] = useState(false);

  const handleToggleMenu = () => {
    setCartOpen(!cartOpen);
  };

  const [lastScrollPos, setLastScrollPos] = useState(0);

  useEffect(() => {
      
    const handleClickOutside = (event) => {
      if (
        cartRef.current &&
        !cartRef.current.contains(event.target) &&
        event.target.id !== 'del-button'
      ) {
        if (cartOpen) {
          setCartOpen(false);
        }
      }
    };  
    setTimeout(() => {
    document.body.addEventListener('click', handleClickOutside);
  }, 200)
    return () => {
      document.body.removeEventListener('click', handleClickOutside);
    };
  }, [cartOpen]);

  const checkScroll = () => {
    const scrollPosition = window.scrollY;
    const windowHeight = window.innerHeight;
    const headerElement = document.getElementById("headerbg");
    if (
      scrollPosition > windowHeight * 0.12 &&
      scrollPosition > lastScrollPos
    ) {
      headerElement.classList.add("show");
    } else if (
      scrollPosition > windowHeight * 0.12 &&
      scrollPosition < lastScrollPos
    ) {
      headerElement.classList.add("show");
    } else if (scrollPosition < windowHeight * 0.12) {
      headerElement.classList.remove("show");
    }
    setLastScrollPos(scrollPosition);
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScroll);
    return () => {
      window.removeEventListener("scroll", checkScroll);
    };
  }, [lastScrollPos]);

  return (
    <>
      <Helmet>
          {/* <title key="title"> {subdomain.charAt(0).toUpperCase() + subdomain.slice(1)} | ZWL Software</title> */}
          <title key="title"> Find your initial sound  | Initial sounds </title>
      </Helmet>
      <div id="header" className="header w-full h-[auto] logo">
        <div className={`md:max-w-[30%] cartpanel menu-right ${cartOpen ? 'open-r' : ''}`}>
            {/* Your menu content */}
            <div className="menu-header">
                <div className="close">
                <IoIosClose onClick={handleToggleMenu} />
                
                </div>
              
            </div>
            <div className="menu-content" id="cart" ref={cartRef} > 
                  <Cart setCartItems={setCartItems} cartItems={ cartItems } />
                </div>
        </div>

        <div id="headerbg"></div>
        <div className="m-4 mb-5 flex justify-between items-center mt-10">
          {/* <img src='/in.png'></img> */}
          <div className="item md:hidden block " onClick={toggleMenu} >
            <RxHamburgerMenu  />
          </div>
          <a href="/" className="no-underline logo">
            <h3 class="font-bold text-2xl text-white ml-4">INITIAL SOUNDS</h3>
          </a>
          <div className="item cart-header mr-1 md:hidden block">
            <BsFillBasket3Fill className="icoCart"  onClick={handleToggleMenu}/>
          </div>

          <div className="item cart-header mr-10 md:flex hidden" id="pcCart">
            <BsFillBasket3Fill className="icoCart"  onClick={handleToggleMenu} />
          </div>
        </div>
      </div>

      <div className={`menu ${isMenuOpen ? 'open' : ''}`}> 
      <div className="menu-header flex justify-end items-end">
        <div className="close">
          <IoIosClose onClick={toggleMenu} />

        </div>
      </div>
      <div className="menu-content"> 
          <div className="menu-list flex-wrap text-center">
            <a href="/"> <p>Home</p></a>
            <a href="/beats"> <p>Beat Library</p></a>
            <a href="/find"> <p>Downloads</p></a>
            {/* <a href="/ghost-productions/"> <p>Ghost productions </p> </a> */}
            {/* <a href="/sample-packs/"> <p>Sample kits </p> </a> */}
            {/* <a href="/free-sample-packs/"> <p>Free downloads </p> </a> */}
          </div>
        </div>
      </div>
    </>
  );
};
