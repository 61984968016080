import React, { useContext, useEffect, useState } from "react";
import {
  AiFillPlayCircle,
  AiFillPauseCircle,
  AiFillProfile,
  AiOutlineUnorderedList,
  AiFillClockCircle,
} from "react-icons/ai";
import { TbMusicExclamation } from "react-icons/tb";
import { FiGrid } from "react-icons/fi";
import { MdVerified } from "react-icons/md";
import { BiSolidCart, BiStar } from "react-icons/bi";
import AudioPlayer from "./audioplayer";
import { Button, Checkbox, Container, Form, Input, RangeSlider, Accordion  } from "rsuite";
import { Link } from "react-router-dom";
import { AudioPlayerContext } from "./AudioPlayerContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import axios from "axios";
import { IoIosClose, IoIosSad, IoMdCheckmark } from "react-icons/io";
import Timer from "./Timer";
import ReactLoading from 'react-loading';

import { HomeInfo } from "./HomeInfo";
import { FaFilter } from "react-icons/fa6";
import { IoFilter } from "react-icons/io5";
import CenteredModal from "./centeredmodal";
export const Tracks = ({ onAddToCart, currency }) => {
  const [KeyPanelOpen, SetKeyPanelOpen] = useState(false);
  const [openFilterPanel, setFilterPanel] = useState(false);
  const [priceRange, setPriceRange] = useState([99,999]); 
  const [bmpRange, setBmpRange] = useState([40,200]); 
  const { setAudioPlayer } = useContext(AudioPlayerContext);
  const [inputValue, setInputValue] = useState("");
  const [tracks, SetTracks] = useState([]);
  const [filteredTracks, setFilteredTracks] = useState([]);
  const [limitedTracks, setLimitedTracks] = useState(null);
  const [fetching, setFetching] = useState(true);
  const [hasLimitedBuys, setHasLimitedBuys] = useState(false);
  const [showBuyScreen, setShowBuyScreen] = useState(false);
  const [buyingTrack, setBuyingTrack] = useState(false);
  const [keyList, setKeyList] = useState([
    { name: "A", isMajor: false, checked: false },
    { name: "A♯/B♭", isMajor: false, checked: false },
    { name: "B", isMajor: false, checked: false },
    { name: "C", isMajor: false, checked: false },
    { name: "C♯/D♭", isMajor: false, checked: false },
    { name: "D", isMajor: false, checked: false },
    { name: "D♯/E♭", isMajor: false, checked: false },
    { name: "E", isMajor: false, checked: false },
    { name: "F", isMajor: false, checked: false },
    { name: "F♯/G♭", isMajor: false, checked: false },
    { name: "G", isMajor: false, checked: false },
    { name: "G♯/A♭", isMajor: false, checked: false },
    { name: "A", isMajor: true, checked: false },
    { name: "A♯/B♭", isMajor: true, checked: false },
    { name: "B", isMajor: true, checked: false },
    { name: "C", isMajor: true, checked: false },
    { name: "C♯/D♭", isMajor: true, checked: false },
    { name: "D", isMajor: true, checked: false },
    { name: "D♯/E♭", isMajor: true, checked: false },
    { name: "E", isMajor: true, checked: false },
    { name: "F", isMajor: true, checked: false },
    { name: "F♯/G♭", isMajor: true, checked: false },
    { name: "G", isMajor: true, checked: false },
    { name: "G♯/A♭", isMajor: true, checked: false }
  ]);
  
  const KeyList = ({ keys, title, onChange }) => (
    <div>
      <p className="text-white">{title}</p>
      {keys.map((key, index) => (
        <span key={index} className="block w-[100%] flex items-center">
          <Checkbox 
            className="mr-2"
            checked={key.checked}
            onChange={() => handleKeyChange(key.name, !key.checked)}
          />
          <span>{key.name} {key.isMajor ? 'Major' : 'Minor'}</span>
        </span>
      ))}
    </div>
  );
 

  const handleKeyChange = (name, checked) => {
    const updatedKeys = keyList.map(key =>
      key.name === name ? { ...key, checked } : key
    );
    setKeyList(updatedKeys);
    SetKeyPanelOpen(true);

  };

  const handleAddToCart = (track) => {
    setShowBuyScreen(true);
    setBuyingTrack(track);
  };
  const clearInput = () =>{
    setInputValue("");
    setFilteredTracks(
      tracks[0]);
    console.log(filteredTracks);
    
    document.getElementById('search-tags').value = "";
    
  }
  
  const handleMouseUp = () => {
    console.log("up");
  };


  const PriceFilter = () =>{
    return(
      <div className=" relative p-2">
      <p className="text-white ml-[-4px] pb-2">Price: </p>
      <RangeSlider id="price-slider"  min={99} progress max={999} defaultValue={priceRange}
        onChangeCommitted={value => (setPriceRange(value))}
      ></RangeSlider>
      <div className="flex justify-between items-center ">
        <span className="text-white" id="min-price">€ {priceRange[0]}</span>
        <span className="text-white" id="max-price">€ {priceRange[1]}</span>
      </div>
    </div>
    );    
  }

  const BPMFilter = () =>{
    return(
      <div className=" relative p-2">
      <p className="text-white ml-[-4px] pb-2">BPM: </p>
      <RangeSlider id="bpm-slider"  min={40} progress max={200} defaultValue={bmpRange}
        onChangeCommitted={value => (setBmpRange(value))}
      ></RangeSlider>
      <div className="flex justify-between items-center ">
        <span className="text-white" id="min-price">{bmpRange[0]}</span>
        <span className="text-white" id="max-price">{bmpRange[1]}</span>
      </div>
    </div>
    );    
  }

  const TrackFilters = () => {
    return (
      <div  className="mt-10">
        {/* <Button
          className="flex button bg-transparent justify-start items-center gap-1"
          onClick={() => setFilterPanel(!openFilterPanel)}
        >
          <p className="text-white flex items-center justify-start gap-3"><IoFilter></IoFilter> Filter</p>
        </Button>

        {openFilterPanel && (
        <div className="bg-s p-3 rounded md:w-1/2 w-full ">
            <Accordion className="text-white p-0" >
            <Accordion.Panel header="Key" defaultExpanded={false} expanded={KeyPanelOpen} onClick={() => {
              SetKeyPanelOpen(!KeyPanelOpen)
            }} >
              <KeyList keys={keyList.filter(key => !key.isMajor)} title="Minor" />
              <KeyList keys={keyList.filter(key => key.isMajor)} title="Major" />
            </Accordion.Panel>

            </Accordion>
            <PriceFilter />
            <BPMFilter />
        </div>)} */}

      </div>);
  }
  
  const handleClick = (text) => {
    let s = text.trim();
    if(inputValue.length <= 0)
    {
      setInputValue(s + " ");
    }
    else{
      setInputValue((prevValue) => `${prevValue} `  + s.trim() );
    }
  };
  
    useEffect(() => {
      console.log("Updated inputValue: " + inputValue);
      
      let keywords = inputValue.split(" ");
 
      // Remove empty keywords
      let filteredKeywords = keywords.filter((keyword) => keyword.trim() !== "");

      // Print the filtered keywords
      filteredKeywords.forEach((keyword) => {
        if(filteredTracks.length !== 0 && keywords.length > 1)
        setFilteredTracks(
          filteredTracks.filter((track) => {
            // if (!showSamplePacks && track.type === "sample-pack") {
            //   return false;
            // }
            
            if (track.tags  && !track.tags.includes(keyword.toUpperCase())) {
              return false;
            }
            return true;
          })
        );
      });
  }, [inputValue]);

  useEffect(() => {
      console.log("currency: " + currency);
      
    const fetchData = async () => {
      try {
      // axios.post('https://www.api.initialsounds.com/index.php', {

          axios.post('http://localhost/inapi/index.php', {
            method: 'fetch',
            loadAssets: '0',
            offset: '0'
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            const newTracks = [response.data[0]];

            newTracks[0].forEach((obj, index) => {
              // obj.src = `http://localhost:3000/tracks/${obj.id}/preview.mp3`;
              obj.src = `http://${obj.location + obj.id}/preview.mp3`;
              obj.img = `-`;
              obj.tags =  JSON.parse(obj.tags);
              obj.currency = currency;
              obj.price = JSON.parse(obj.price);
            });
            
            let limitedSongs = newTracks[0].filter(track => track.limited === 1);
            let otherSongs = newTracks[0].filter(track => track.limited === 0);
            newTracks[0] = [...limitedSongs, ...otherSongs];
            setLimitedTracks(limitedSongs.length);
            setFetching(false);

            SetTracks(newTracks);
            console.log(tracks);
            setFilteredTracks(
                newTracks[0].filter((track) => {
                  
                  return true;
                })
              );
            console.log('POST request successful:', response.data);

          })
          .catch(error => {
              console.error('Error making POST request:', error);
              setFetching(false);

          }) 
  
      } catch (error) {
        console.error('Error making POST request:', error);
      }
    };
  setTimeout(() => {
    fetchData(); // Call the function to make the POST request when component mounts
  }, 1000);
    

  }, []); // The empty dependency array ensures this effect only runs once on component mount

  useEffect(() => {
    setTimeout(() => {
      // const newTracks = [
      //   {
      //     id: 0,
      //     type: "beat",
      //     author: "Initial sound",
      //     title: "ZWAAN | Cochise type beat",
      //     bpm: "154 BPM",
      //     songkey: 'Gmin',
      //     src: "http://localhost:3000/tracks/0/preview.mp3",
      //     price: "0.50",
      //     currency: "€",
      //     img: "oig_",
      //     url: null,
      //     authorUrl: "initial sound",
      //     tags: ["hard", "808", "powerful"],
      //   } 
      // ];
      
      // setFilteredTracks(
      //   newTracks.filter((track) => {
      //     // if (!showSamplePacks && track.type === "sample-pack") {
      //     //   return false;
      //     // }
      //     // if (!showGhostProductions && track.type === "ghost-production") {
      //     //   return false;
      //     // }
      //     return true;
      //   })
      // );
      // Update your component state here
    }, 2000); // Delay of 2 seconds
  }, []);
  const tags = [
    { tag: "808" },
    { tag: "calm" },
    { tag: "hard" },
    { tag: "majestic" },
    { tag: "rnb" },
    { tag: "fast" },
    { tag: "piano" },
    { tag: "powerful" },
    { tag: "slow" },
    { tag: "cinematic" },
    { tag: "epic" },
    { tag: "acoustic" },
    { tag: "emotional" },
    { tag: "melodic" },
  ];
 
  const [view, setView] = useState(sessionStorage.getItem("inView") || "card");
  useEffect(() => {
    sessionStorage.setItem("inView", view);
  }, [view]);

  const [currentPlayingTrack, setCurrentPlayingTrack] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  useEffect(() => {
    if (currentPlayingTrack) {      
      setAudioPlayer(
        <AudioPlayer
          src={currentPlayingTrack.src}
          trackInfo={currentPlayingTrack}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
          onAddToCart={onAddToCart}
        />
      );
    }
  }, [currentPlayingTrack, isPlaying]);

  useEffect(() => {

  }, );
  const StPriceRange = (value) => {
      setPriceRange(value);
  } 
  
  const handleModalClose = () =>{
    setShowBuyScreen(false);
    setBuyingTrack(null);
  }

  return (
    <>
      <CenteredModal show={showBuyScreen} buyingTrack={buyingTrack} onBuy={(index) => {
      buyingTrack.priced = buyingTrack.price[index];
      buyingTrack.version = index;
      onAddToCart(buyingTrack);
}}
   onClose={handleModalClose}   />

      <Container className="r md:p-0 p-5 max-w-[1200px]">
      <HomeInfo />
      <div className="mt-40">
        {/* <p className="text-yellow-400 my-10">We are working on the site. Don't panic, you might come across some errors.</p> */}
        <h1 className="md:ml-0 text-white text-2xl ">
          Explore the library
        </h1>
        <p className="md:ml-0">
          {" "}
          Looking for something? Find everything in one place.{" "}
        </p>
        <Form className="w-full md:pl-0  ">
          <div className="search-tags gap-3  w-full justify-start  items-center">
            <div className="w-[70%]  md:w-[50%]">
              <div className="search-input">
                <div className="in flex justify-end items-center w-full h-full">
                <Input
                  name="seach"
                  value={inputValue}
                  onChange={setInputValue}
                  id="search-tags"
                  placeholder="Enter keywords with a space.."
                  className="input  w-[50%]"
                />
                <div className="clear-input absolute float-right text-white button bg-transparent " id="clear-input" onClick={clearInput}> <IoIosClose/> </div>
                </div>
              </div>
            </div>

            {tags &&
              tags.map((tag, index) => (
                <Button
                  className="button tagbtn"
                  onClick={() => handleClick(tag.tag)}
                >
                  {tag.tag}
                </Button>
              ))}
          </div>
        </Form>
      <TrackFilters />

      </div>
      {view === "card" ? (
        <div className="pb-20 ">
          {/* <span className="text-white"> Library: </span> */}
           

          <div class="view-buttons   flex gap-2 justify-between items-center">
            <div className="md:hidden block">
              {/* <Button className="button">
                <span className="text-black filter">filter</span>
              </Button> */}
            </div>
            <div className="md:flex hidden">
              {/* <div className="mx-2 flex justify-center items-center">
                <input
                  checked={showGhostProductions}
                  onChange={(e) => setShowGhostProductions(e.target.checked)}
                  type="checkbox"
                  className="checkbox"
                  id="ghostProductions"
                  name="ghostProductions"
                />
                <label for="ghostProductions">Beats</label>
              </div>
              <div className="mx-2 flex justify-center items-center">
                <input
                  checked={showGhostProductions}
                  onChange={(e) => setShowGhostProductions(e.target.checked)}
                  type="checkbox"
                  className="checkbox"
                  id="ghostProductions"
                  name="ghostProductions"
                />
                <label for="ghostProductions">Ghost Productions</label>
              </div>
              <p> | </p>
              <div className="mx-2 ml-0 flex justify-center items-center">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={showSamplePacks}
                  onChange={(e) => setShowSamplePacks(e.target.checked)}
                  id="samplePacks"
                  name="samplePacks"
                />
                <label for="samplePacks">Sample Packs</label>
              </div> */}
            </div>
            <div className="flex gap-3">

              <button
                className="flex justify-start items-center gap-1"
                onClick={() => setView("card")}
              >
                <FiGrid /> Grid view
              </button>
              <button
                className="flex justify-start items-center gap-1"
                onClick={() => setView("list")}
              >
                <AiOutlineUnorderedList /> List view
              </button>
            </div>
          </div>
          
          {/* grid  */}
          <div>
  {filteredTracks &&
    filteredTracks.map((track, index) => (
      <>
        {index === 0 && limitedTracks > 0 && (
          <>
            <div className="my-5 mt-10 w-full">
              <h2 className="text-white text-2xl">| NEW LIMITED BUYS </h2>
              <p>Be the owner of an exclusive instrumental before anyone else.</p>
            </div>
            <div className="tracks-container flex-wrap gap-5 mt-10 justify-center items-center">
              {filteredTracks &&
                filteredTracks.map((track, index) => (
                  <div key={index} className="track-wrapper">
                    {track.limited === 1 && (
                      <div className="image-wrapper">
                        {track.img ? (
                          <img
                            src={`http://${track.location + track.id}/` + "img" + ".jpg"}
                            alt={track.title + " Image"}
                          />
                        ) : (
                          <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={270} height={270} />
                        )}
                        <div className="overlay">
                          <div className="play">
                            <button
                              onClick={() => {
                                if (
                                  currentPlayingTrack &&
                                  currentPlayingTrack.src === track.src
                                ) {
                                  setIsPlaying(!isPlaying);
                                } else {
                                  setCurrentPlayingTrack(track);
                                  setIsPlaying(true);
                                }
                              }}
                            >
                              {currentPlayingTrack &&
                              currentPlayingTrack.src === track.src &&
                              isPlaying ? (
                                <AiFillPauseCircle />
                              ) : (
                                <AiFillPlayCircle />
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="track-info">
                            {track.author ? (
                              <a
                                className="author pointer-events-none"
                                href={"/profile/" + track.authorUrl}
                              >
                                <span className="flex justify-start items-center gap-1">
                                  <span className="text-blue-400">
                                    <MdVerified />
                                  </span>{" "}
                                  {track.author}
                                </span>
                              </a>
                            ) : (
                              <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={100} height={20} />
                            )}
                            {track.title ? (
                              <a className="pointer-events-none" href={"/view-track/" + track.url + "/" + track.id}>
                                <span className="title">{track.title}</span>
                              </a>
                            ) : (
                              <Skeleton className="mt-2" baseColor="#151515" highlightColor="#1a1a1a" count={1} width={200} height={30} />
                            )}
                            {track.title ? (
                              <div className="flex">
                                <span className="price ">{"€" + JSON.parse(track.price[0])}</span>
                                <div className="dot"> -</div>
                                <span className="bpm "> {track.bpm} BPM - {track.songkey}</span>
                              </div>
                            ) : (
                              <div className="flex gap-2">
                                <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={40} height={20} />
                                <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={40} height={20} />
                              </div>
                            )}
                            <br />
                          </div>
                          {track.price[0] >= 0 ? (
                            <Button className={`cartbtn button mt-5 flex gap-1 ${track.type == "limited" ? "limitedBtn" : ""}`} onClick={() => handleAddToCart(track)}>
                              {" "}
                              <BiSolidCart /> <span> {"€" + " " +  track.price[0]}</span>
                            </Button>
                          ) : (
                            <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={50} height={50} />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </>
        )}

        {index == limitedTracks && (
          <>
          {index == limitedTracks && (
            <div className={`my-5 ${limitedTracks > 0 ? 'mt-40' : 'mt-10'} `}>
              <h2 className="text-white text-2xl">| LIBRARY </h2>
              <p>Find your sound in our collection of instrumentals.</p>
            </div>)}
            <div className="tracks-container flex-wrap gap-5 mt-10 justify-center items-center">
              {filteredTracks &&
                filteredTracks
                  .filter((_, index) => index >= limitedTracks)
                  .map((track, index) => (<>
                  <div key={index} className="track-wrapper">
                    {track && (
                      <div className="image-wrapper">
                        {track.img ? (
                          <img
                            src={`http://${track.location + track.id}/` + "img" + ".jpg"}
                            alt={track.title + " Image"}
                          />
                        ) : (
                          <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={270} height={270} />
                        )}
                        <div className="overlay">
                          <div className="play">
                            <button
                              onClick={() => {
                                if (
                                  currentPlayingTrack &&
                                  currentPlayingTrack.src === track.src
                                ) {
                                  setIsPlaying(!isPlaying);
                                } else {
                                  setCurrentPlayingTrack(track);
                                  setIsPlaying(true);
                                }
                              }}
                            >
                              {currentPlayingTrack &&
                              currentPlayingTrack.src === track.src &&
                              isPlaying ? (
                                <AiFillPauseCircle />
                              ) : (
                                <AiFillPlayCircle />
                              )}
                            </button>
                          </div>
                        </div>
                        <div className="flex justify-between items-center">
                          <div className="track-info">
                            {track.author ? (
                              <a
                                className="author pointer-events-none"
                                href={"/profile/" + track.authorUrl}
                              >
                                <span className="flex justify-start items-center gap-1">
                                  <span className="text-blue-400">
                                    <MdVerified />
                                  </span>{" "}
                                  {track.author}
                                </span>
                              </a>
                            ) : (
                              <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={100} height={20} />
                            )}
                            {track.title ? (
                              <a className="pointer-events-none" href={"/view-track/" + track.url + "/" + track.id}>
                                <span className="title">{track.title}</span>
                              </a>
                            ) : (
                              <Skeleton className="mt-2" baseColor="#151515" highlightColor="#1a1a1a" count={1} width={200} height={30} />
                            )}
                            {track.title ? (
                              <div className="flex">
                                <span className="price ">{"€" + " " +  track.price[0]}</span>
                                <div className="dot"> -</div>
                                <span className="bpm "> {track.bpm} BPM - {track.songkey}</span>
                              </div>
                            ) : (
                              <div className="flex gap-2">
                                <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={40} height={20} />
                                <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={40} height={20} />
                              </div>
                            )}
                            <br />
                          </div>
                          {track.price[0] >= 0 ? (
                            <Button className={`cartbtn button mt-5 flex gap-1 ${track.type == "limited" ? "limitedBtn" : ""}`} onClick={() => handleAddToCart(track)}>
                              {" "}
                              <BiSolidCart /> <span> {"€" + " " +  track.price[0]}</span>
                            </Button>
                          ) : (
                            <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={50} height={50} />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  </>))}
            </div>
          </>
        )}
      </>
    ))}
</div>


         
        </div>
        
      ) : (
        <> 
        <div className="list-view flex-wrap gap-5 justify-center items-center mb-10 pb-20  ">
          {/* <span className="md:block hidden text-white"> Library: </span> */}

          <div class="view-buttons   flex gap-2 justify-between items-center">
            <div className="md:hidden block">
              {/* <Button className="button">
                <span className="text-black filter">filter</span>
              </Button> */}
            </div>
            <div className="md:flex hidden">
              {/* <div className="mx-2 flex justify-center items-center">
                <input
                  checked={showGhostProductions}
                  onChange={(e) => setShowGhostProductions(e.target.checked)}
                  type="checkbox"
                  className="checkbox"
                  id="ghostProductions"
                  name="ghostProductions"
                />
                <label for="ghostProductions">Beats</label>
              </div>
              <div className="mx-2 flex justify-center items-center">
                <input
                  checked={showGhostProductions}
                  onChange={(e) => setShowGhostProductions(e.target.checked)}
                  type="checkbox"
                  className="checkbox"
                  id="ghostProductions"
                  name="ghostProductions"
                />
                <label for="ghostProductions">Ghost Productions</label>
              </div> */}
              {/* <p> | </p>
              <div className="mx-2 ml-0 flex justify-center items-center">
                <input
                  type="checkbox"
                  className="checkbox"
                  checked={showSamplePacks}
                  onChange={(e) => setShowSamplePacks(e.target.checked)}
                  id="samplePacks"
                  name="samplePacks"
                />
                <label for="samplePacks">Sample Packs</label>
              </div> */}
            </div>
            <div className="flex gap-3">
              <button
                className="flex justify-start items-center gap-1"
                onClick={() => setView("card")}
              >
                <FiGrid /> Grid view
              </button>
              <button
                className="flex justify-start items-center gap-1"
                onClick={() => setView("list")}
              >
                <AiOutlineUnorderedList /> List view
              </button>
            </div>
          </div>

         


          {filteredTracks &&
            filteredTracks.map((track, index) => (      
              <>

                {index === 0 && limitedTracks > 0 && (
                  <div className="my-5 mt-10">
                    <h2 className="text-white  text-2xl">| NEW LIMITED BUYS </h2>
                    <p>Be the owner of an exclusive instrumental before anyone else.</p>
                  </div>
                )} 

                 {index == limitedTracks && (
                  <div className={`my-5 ${limitedTracks > 0 ? 'mt-40' : 'mt-10'} `}>
                    <h2 className="text-white   text-2xl">| LIBRARY   </h2>
                      <p>Find your sound in our collection of instrumentals.</p>
                  </div>
                )} 
             
          
              <div
                key={index}
                className="track-item flex gap-4 justify-between items-center w-full"
              >     
             
                <div className="track-wrapper-list flex justify-center items-center gap-3">
                  <div className=" image-wrapper max-w-[90px]">  
                  {track.img ? (
                    <img
                      src={`http://${track.location + track.id}/` + "img" + ".jpg"}

                      alt={track.title + " Image"}
                    />
                      ) : (
                      <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={70} height={70}  />
                    )}

                    <div className="overlay">
                      <div className="play">
                        <button
                          onClick={() => {
                            if (
                              currentPlayingTrack &&
                              currentPlayingTrack.src === track.src
                            ) {
                              setIsPlaying(!isPlaying);
                            } else {
                              setCurrentPlayingTrack(track);
                              setIsPlaying(true);
                            }
                          }}
                        >
                          {currentPlayingTrack &&
                          currentPlayingTrack.src === track.src &&
                          isPlaying ? (
                            <AiFillPauseCircle />
                          ) : (
                            <AiFillPlayCircle />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="track-info pb-2 pt-2 pl-1">
                    {track.author ? (
                      <a
                        className="author pointer-events-none"
                        href={"/profile/" + track.authorUrl}
                      >
                        <span className="flex justify-start items-center gap-1">
                        <span className="text-blue-400"><MdVerified /></span> {track.author}
                        </span>
                      </a>
                    ) : (
                      <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={70} />

                    )}
                    <a
                      className="title pointer-events-none"
                      href={"/view-track/" + track.url + "/" + track.id}
                    >
                      <span className="title">{track.title}</span>
                    </a>
                    {track.price[0]>= 0 ? (
                    <div className="md:flex flex-wrap gap-1 ">
                      <span className="price ">
                        {"€" + " " +  track.price[0]}
                      </span>
                      <span className="bpm "> - {track.bpm}  BPM </span> - <span className="bpm"> {track.songkey}</span>
                      <div className="mobile-tags md:hidden" >
                        {track.tags && track.tags.length >=  0 ? (
                          track.tags.map((tag, tagIndex) => (
                            <Button className="button tagbtn mr-1 flex justify-start items-center">
                              <span className="hashtag mr-2"># </span>
                              <span className="tag-meta"> {tag}</span>
                            </Button>
                          ))
                        ) : (
                          Array.from({ length: 3 }).map((_, index) => (
                            <Skeleton className="mx-2"  baseColor="#151515" highlightColor="#1a1a1a" count={1} width={80} height={40} />
                          ))
                        )}
                      </div>
                    </div> 
                    ) : (
                      <div className="flex">
                            <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={200} />

                      </div>
                    )}
                  </div>
                </div>
                <div className="flex justify-between items-center"></div>
                {track.price[0] >= 0 && (
                <div className="pr-4 md:hidden block">
                  
                  <Button className={`xpy-4 button flex gap-1 cartbtn ${track.type=="limited" ? "limitedBtn" : ""}`}  onClick={() => handleAddToCart(track)}>
                    {" "}
                    <BiSolidCart className="icoCart" />{" "}
                  </Button>
                </div>
                )}
                <div className="track-btns pr-4 md:flex hidden justify-start items-center  ">
                  <div className="tags no-wrap">
                   
                  {track.tags && track.tags.length >=  0 ? (
                        track.tags.map((tag, tagIndex) => (
                          <>
                            <Button className="button tagbtn mr-3 flex justify-start items-center">
                              <span className="hashtag mr-2"># </span>
                              <span className="tag-meta"> {tag}</span>
                            </Button> 
                            {track.limited === 1 &&(
                              <div className="limited-track mr-3 flex gap-1 justify-start items-center">
                                <AiFillClockCircle />
                                <p><Timer initialServerTime={ new Date(track.upload.replace(' ', 'T')).getTime()} /></p>
                              </div>
                            )}
                          </>
                        ))
                        
                      )
                      
                      : (
                        
                        Array.from({ length: 3 }).map((_, index) => (
                           <Skeleton className="mx-2"  baseColor="#151515" highlightColor="#1a1a1a" count={1} width={80} height={40} />
                        ))
                      )}
                  </div>
                  {track.price[0] >= 0 ? (
                  <Button className={`md:hidden block cartbtn  button   flex gap-1 { ${track.type=="limited" ? "limitedBtn" : ""}`} onClick={() => handleAddToCart(track)}>
                    {" "}
                    <BiSolidCart className="icoCart" />{" "}
                    <span> {"€" + " " +  track.price[0]}</span>
                  </Button>
                  ) : (
                    <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={170} height={50} />
                  )}
                </div>
              </div>
              </>
            ))}
            
        </div>
        </>
      )}

        {filteredTracks && filteredTracks.length == 0 && fetching && (
          <div className="loading mb-40">
            <div className="flex justify-center items-center w-full">
              <ReactLoading type={"bars"} color={"white"}  height={'10%'} width={'10%'} />
            </div>
            <p className="text-white text-center"> loading...</p>

          </div>
        )}
        {filteredTracks && filteredTracks.length == 0 && !fetching && (
          <div className="loading mb-40">
            <div className="flex justify-center items-center w-full text-white text-7xl">
              <TbMusicExclamation />
            </div>
            <p className="text-white text-center mt-2"> No results found.</p>

          </div>
        )}
      {/* {currentPlayingTrack && (
        <AudioPlayer
          src={currentPlayingTrack.src}
          trackInfo={currentPlayingTrack}
          isPlaying={isPlaying}
          setIsPlaying={setIsPlaying}
        />
      )} */}
      
      </Container>
    </>
  );
};


