import React, { useEffect, useState } from 'react';
import { BiSolidCart } from 'react-icons/bi';
import { Modal, Button } from 'rsuite';

const licenseOptions = [
  { label: "Tagged WAV/MP3" },
  { label: "Untagged WAV/MP3" },
  { label: "Premium stems + WAV/MP3" }
];

const CenteredModal = ({ show, onClose, onBuy, buyingTrack }) => {
  const [showModel, setShow] = useState(false);
  const [track, setTrack] = useState(false);

  useEffect(() => {
    // console.log(show);
    setShow(show);
  }, [show]);

  useEffect(() => {
    if (buyingTrack) {

      setTrack(buyingTrack);
    }
  }, [buyingTrack]);
  //closes modal, resets data
  const OnTheChloos = () => {
    onClose();

    setTimeout(() => {
      setTrack(null);
    }, 500);
  }

  return (
    <Modal open={showModel} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClose={OnTheChloos}>
      <Modal.Header>
        <Modal.Title>Select your license</Modal.Title>
      </Modal.Header>
      <Modal.Body className='block z-50'>
        <h4 className='text-1xl'>{track?.title}</h4>
        <div className='flex'>
          {licenseOptions.map((option, index) => (
            <div key={index} className='w-1/3 flex flex-col justify-between min-h-[200px] min-w-[300px]'>
              <div>{option.label}</div>
              {track != null && Array.isArray(track?.price) &&
                <Button onClick={() => { onBuy(index); OnTheChloos(); }} className='cartbtn button  gap-1 md:w-1/2 !pr-4' appearance="primary">
                <span className=' flex gap-2 item-center '><BiSolidCart /> {track?.currency + ' ' + track.price[index]}</span>
              </Button>
              }

            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={OnTheChloos} appearance="secondary">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CenteredModal;
