import { useEffect, useState } from "react";
import { AiFillPlayCircle } from "react-icons/ai";
import { BiSolidCart, BiSolidTrash } from "react-icons/bi";
import { BsFillBasket3Fill } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import { MdVerified } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { Button } from "rsuite";

export const Cart = ({cartItems, setCartItems}) => {
    
    const [totalPrice, setTotalPrice] = useState(0);

    useEffect(() => {
        
        cartItems.map((item, index) => (
          console.log(item?.title),
          console.log(item?.version),
          item.priced = item.price[item.version]  
          
        ));
        // Calculate the total price
        const totalPrice = cartItems.reduce((acc, item) => acc + item.priced, 0);
        setTotalPrice(totalPrice);
    }, [cartItems]);

    const delItemFromCart = (_id) => {
        const filteredArray = cartItems.filter(item => item.id !== _id);
        console.log(filteredArray);
        setCartItems(filteredArray);
    }
    
    return (
        <div className="flex flex-col justify-between h-full">
        <div className="flex flex-col justify-between md:min-h-[80vh] min-h-[60vh]  items-center">
          <div className="w-full">
            <h3 className="text-white text-center flex gap-2 justify-center items-center cc"><BsFillBasket3Fill />  </h3>
            <h3 className="text-white text-center flex gap-2 justify-center items-center w-full">Cart Items  </h3>
            <br/>
            <div className="overflow-y-auto overflow-x-hidden w-full h-[40vh] my-4">
              {cartItems.length === 0 ? (
                <div className="h-full w-full flex justify-center items-center">
                  <div>
                    <h5 className="text-white font-light my-3">Your cart is empty!</h5>  
                  </div>
                </div>
              ) : (
                cartItems.map((item, index) => (
                  <div key={index} className="track-item flex gap-4 justify-between items-center w-full">
                    <div className="w-full track-wrapper-list flex justify-center items-center gap-3">
                      {item.img ? (
                        <img
                          className="min-h-[90px]"
                          src={`http://${item.location + item.id}/` + "img" + ".jpg"}
                          alt={item.title + " Image"}
                        />
                      ) : (
                        <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={70} height={70} />
                      )}
                      <div className="track-info pb-2 pt-2 pl-1 w-full">
                        {item.author ? (
                          <a className="author pointer-events-none" href={"/profile/" + item.authorUrl}>
                            <span className="flex justify-start items-center gap-1">
                              <span className="citm text-blue-400"><MdVerified /></span> {item.author}
                            </span>
                          </a>
                        ) : (
                          <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={70} />
                        )}
                        <a className="title pointer-events-none" href={"/view-track/" + item.url + "/" + item.id}>
                          <span className="title">{item.title}</span>
                        </a>
                        {item.priced >= 0 ? (
                          <div className="md:flex flex-wrap gap-1">
                            <span className="price">{item.currency + item.priced}</span>
                            <span className="bpm"> - {item.bpm} </span>
                            <span className="bpm"> {item.songkey}</span>
                            <div className="mobile-tags md:hidden">
                              {item.tags && item.tags.length >= 0 ? (
                                item.tags.map((tag, tagIndex) => (
                                  <Button className="button tagbtn mr-1 flex justify-start items-center">
                                    <span className="hashtag mr-2">#</span>
                                    <span className="tag-meta"> {tag}</span>
                                  </Button>
                                ))
                              ) : (
                                Array.from({ length: 3 }).map((_, index) => (
                                  <Skeleton className="mx-2" baseColor="#151515" highlightColor="#1a1a1a" count={1} width={80} height={40} />
                                ))
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="flex">
                            <Skeleton baseColor="#151515" highlightColor="#1a1a1a" count={1} width={200} />
                          </div>
                        )}
                      </div>
                      <div className=" ">
                        <Button className="del-button button bg-transparent tagbtn mr-1 flex justify-start items-center" id="del-button" onClick={() => delItemFromCart(item.id)}>
                          <BiSolidTrash />
                        </Button>
                      </div>
                    </div>
                    <div className="flex justify-between items-center"></div>
                  </div>
                ))
              )}
            </div>
          </div>
          {cartItems.length > 0 && (
            <div className="w-full">
              <div className="flex justify-between gap-2 w-full items-center">
                <h5 className="text-white font-light text-start">YOUR TOTAL: </h5>
                <h5 className="text-white font-light text-start">€ {totalPrice} </h5>
              </div>
              <hr />
              <a href="/checkout" className=" w-[100%] flex gap-1 justify-center items-center">
                <Button className="cartbtn checkout-button button w-full fixed flex gap-1 justify-center items-center">
                  Proceed to checkout <BiSolidCart />
                </Button>
              </a>
            </div>
          )}
        </div>
      </div>
      
      
    );
};

