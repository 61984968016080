import { Button, Container, Content } from "rsuite";
import { Header } from "./Header";
import { useParams } from "react-router-dom";
import $ from "jquery";
import CarouselComponent from './v';


import "./App.css";
export const ViewTrack = (meta) => {
  let { title, id } = useParams();

  return (
    <>
      <Header></Header>
      <Container className="w-full h-full">
        <Content className="mt-40">{title}
        
        <div className="container min-w-full min-h-[75%]  py-10 px-3">
            <div className="md:flex gap-5 justify-center min-w-full my-10 no-flex-mobile">
                <div className="md:w-1/3 inner">
                    <CarouselComponent></CarouselComponent>
                </div>
                {/* comp/img/packs/${id}/cover. */}
                <div className="md:w-1/3 ">
                    <p className="price underline text-sm"><a href={`/${meta.author}`}> {meta.author}</a></p>
                    <h1 className="text-left text-3xl "> {meta.name} </h1>
                    <div className="flex">
                        <p className="price text-slate-600 mt-1 text-xl line-through mr-3">{meta.currency} {meta.old_price}</p>
                        <p className="price text-slate-600 mt-1 text-xl">{meta.currency} {meta.price}</p>
                    </div>
                    <div className="search-component  m-0 mt-0 my-0" style={{marginLeft: '0px', marginTop: '-20px'}}>
                        <div className="search-buttons md:flex flex-wrap md:gap-2 gap-4 m-0 mt-0">
                            <Button appearance="primary" className="button dpsh5 search-button w-full bg-blue-600 mt-0 ToCart" style={{marginRight: '0px'}}
                                type="submit" id="next">Add to cart</Button>
                            <Button appearance="primary"
                                className="button dpsh5 mt-2 flex justify-center items-center search-button w-full bg-blue-600 mt-0 ToCart"
                                type="submit" id="next" style={{backgroundColor: '#429df4'}}>Buy with
                                <div className="pl-3 flex justify-center items-center mr-3">{/* include 'paypal.svg' */}</div>
                            </Button>
                        </div>
                    </div>
                    {/* ... rest of your code ... */}
                </div>
            </div>
        </div>
     
        
        
        
        </Content>
      </Container>
    </>
  );
};
