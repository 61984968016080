import { FaStar } from "react-icons/fa6";
import { IoMusicalNote, IoStarHalf } from "react-icons/io5";
import { VideoPlayer } from "./VideoPlayer";
import { BsArrowRight, BsFillPersonFill, BsMusicNoteBeamed, BsStarFill } from "react-icons/bs";
import { IoIosClose, IoIosSad, IoMdCheckmark } from "react-icons/io";
import ScrollingText from "./ScrollingText";
import { BiSearch, BiSearchAlt } from "react-icons/bi";
import { Button } from "rsuite";
import { MdExplore, MdOutlineLibraryMusic } from "react-icons/md";

export const HomeInfo = () => {

    const words = ['Media', "Vocals" , 'Rap', 'Tiktok', "Gaming" , 'Television', 'Movies', 'Advertising', 'Youtube'];
    const interval = 3000; // Interval between word changes in milliseconds

    return(
      
        <div className="dwcard md:mt-[150px] pt-15 mt-[100px] px-10 pb-20"  >
        <span><h1 className=" text-white mob-title-5 mb-5 font-extrabold uppercase">Your <span className="price">custom Beat</span> Marketplace</h1></span>
        {/* <span><h3 className=" text-white text-2xl mb-5 font-light md:flex gap-3">Own an instrumental for: <span className="text-yellow-400"><ScrollingText  words={words} interval={interval} /></span> </h3></span> */}
          <div className="flex">
            <div className="md:w-1/2 w-full">
              <p className="flex"><span className="text-green-500 text-2xl mr-2"><IoMdCheckmark /></span><span className=""> Unique & High quality beats made by a experienced producer. </span></p>
              <p className="flex"><span className="text-green-500 text-2xl mr-2"><IoMdCheckmark /></span><span className=""> Stems, WAVs, MP3s instantly delivered.</span></p>
              <p className="flex"><span className="text-green-500 text-2xl mr-2"><IoMdCheckmark /></span><span className=""> Own 100% of the tracks. All beats are shipped royality free.</span></p>
              <p className="flex"><span className="text-green-500 text-2xl mr-2"><IoMdCheckmark /></span><span className=""> Unlimited lease beats. All beats are shipped for unlimited leasing. </span></p>
              <p className="flex"><span className="text-green-500 text-2xl mr-2"><IoMdCheckmark /></span><span className=""> Limited beat buys are limited to 1 copy: you will be the exclusive owner.</span></p>
              <Button className='cartbtn mt-10'> <div className='flex justify-center items-center gap-2  ' >Explore the libray <BiSearchAlt /></div> </Button>
              
          </div>
        </div>
        {/* <div className="w-full reviews mt-20 ">
          <div className="text-end text-white"> Customer Rating: </div>
          <div className="  h-[25px] flex justify-end items-center gap-3 rounded py-5"><span className="text-white stars flex justify-start items-center gap-1">
            <span className="bg-yellow-500 text-white p-1"><FaStar /></span>
            <span className="bg-yellow-500  text-white p-1"><FaStar /></span>
            <span className="bg-yellow-500  text-white p-1"><FaStar /></span>
            <span className="bg-yellow-500  text-white p-1"><FaStar /></span>
            <span className="bg-yellow-500  text-white p-1"><IoStarHalf /></span>
          </span> 
          <span>|</span> 
          <div className="text-white"> 4.8/5</div></div> 
        </div> */}
    </div>
    );
}