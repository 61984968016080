import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Skeleton from 'react-loading-skeleton';

const Timer = ({ initialServerTime }) => {
  const [countdown, setCountdown] = useState(0);
  const [cttime, setCurrentTime] = useState(null);

  useEffect(() => {
    // Get the initial server time from local storage or use the provided initialServerTime
    // const storedServerTime = localStorage.getItem('serverTime');
    const storedServerTime = null;
    const serverTime = storedServerTime ? parseInt(storedServerTime) : initialServerTime;

    // // Calculate the initial countdown

    const difference = serverTime - cttime;
    setCountdown(difference);

    // Store the initial server time in local storage
    // localStorage.setItem('serverTime', serverTime.toString());

    // Set up interval to update countdown
    const timer = setInterval(() => {
      const ct = new Date().getTime();
      const difference = serverTime - ct;
      setCountdown(difference);
    }, 1000);
    setCountdown(-1);

    return () => clearInterval(timer);
  }, [initialServerTime]);

  // Convert milliseconds to days, hours, minutes, seconds
  const days = Math.floor(countdown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countdown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countdown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countdown % (1000 * 60)) / 1000);

  return (
    <div>
      {initialServerTime && countdown > 0 ? (
        <div>
          {days > 0 && <span>{days} | </span>}
          <span>{hours < 10 ? `0${hours}` : hours}</span>:
          <span>{minutes < 10 ? `0${minutes}` : minutes}</span>:
          <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
        </div>
      ) : (
        countdown === -1 ? (
          <Skeleton className="mx-2" baseColor="#151515" highlightColor="#1a1a1a" count={1} width={75} height={20} /> // Assuming Skeleton is a valid component
        ) : (
          <div>Countdown finished</div>
        )
      )}
    </div>
  );
};

export default Timer;



