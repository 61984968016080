import { useState } from "react";

const CartManager = () => {
    const [cartIsOpen, setCartIsOpen] = useState(false);
    const [cartItems, setCartItems] = useState([]);

    // Combine state variables into a single object
    const cartState = {
        cartIsOpen,
        setCartIsOpen,
        cartItems,
        setCartItems
    };

    return cartState;
};

export default CartManager;