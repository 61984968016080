import axios from "axios";
import { useEffect, useState } from "react";
import { BiDownload } from "react-icons/bi";
import { BsArrowLeft } from "react-icons/bs";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";
import { Button, Container } from "rsuite";
import { LuAlertTriangle } from "react-icons/lu";
export const Orders = () => {
    const [orderData, setOrderData] = useState(null);
    const [dwLink, setDwLink] = useState(null);
    const { orderid } = useParams();
    const [orderFilled, setOrderFilled] = useState(false);
    useEffect(() => {
        console.log(orderid);
        sessionStorage.removeItem("cartItems");
        if (!orderFilled)
            try {
                // axios.post('https://www.api.initialsounds.com/index.php', {

                axios.post('http://localhost/inapi/index.php', {
                    method: "fetch",
                    orderREF: orderid,
                },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    })
                    .then(response => {
                        //success
                        if (response.data.status === "paid")
                        {    
                            // axios.post('https://www.api.initialsounds.com/index.php', {
                            axios.post('http://localhost/inapi/index.php', {
                                    method: "fetchdownload",
                                    orderREF: orderid,
                                },
                                    {
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    })
                                    .then(response => {
                                        setDwLink(response.data);
                                        setOrderFilled(true);

                                    })
                                    .catch(error => {
                                        console.error('Error making POST request:', error);
                                    })
                        }
                        setOrderData(response.data);
                    })
                    .catch(error => {
                        console.error('Error making POST request:', error);
                    })

            } catch (error) {
                console.error('Error making POST request:', error);
            }

    }, []);

    useEffect(() => {
        if (orderData && orderData.status !== "paid") {

            const timer = setTimeout(() => {
                window.location.reload();
            }, 2 * 60 * 1000); // 5 minutes in milliseconds

            return () => clearTimeout(timer); // Clear the timer if the component is unmounted
        }
    }, [orderData]);
    const handelRedeem = () => {
        try {
            // axios.post('https://www.api.initialsounds.com/index.php', {

            axios.post('http://localhost/inapi/index.php', {
                method: "gen_coupon",
                orderREF: orderid,
            },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    //success
                    console.log(response);
                   
                })
                .catch(error => {
                    console.error('Error making POST request:', error);
                })

        } catch (error) {
            console.error('Error making POST request:', error);
        }
    }
    const downloadOrder = () => {
        try {
            // axios.post('https://www.api.initialsounds.com/index.php', {

            axios.post('http://localhost/inapi/index.php', {
                method: "download",
                orderREF: orderid,
            },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    //success
                    console.log(response);
                    // Create a hidden <a> element
                    const link = document.createElement('a');
                    link.href = response.data;
                    link.download = `initialsounds.com_${orderid}.zip`; // Optional: specify a filename for the download
                    link.style.display = 'none';
                    document.body.appendChild(link);
                    // Programmatically click the link to start the download
                    link.click();
                    // Clean up
                    document.body.removeChild(link);
                })
                .catch(error => {
                    console.error('Error making POST request:', error);
                })

        } catch (error) {
            console.error('Error making POST request:', error);
        }
    }

    return (
        <div className="my-40 flex justify-center items-center">
            <Container className="md:p-0 p-5 my-5 max-w-[1200px] h-full flex justify-center items-center">
                <div className="flex flex-wrap gap-4 justify-between items-start h-full w-full">
                    <div className="w-full">

                        {orderData && orderData.status === "paid" ? (
                            <></>
                        ) : (
                            <p className="text-yellow-400 my-2 mb-10">Your order is being processed, please wait and refresh the page in a few minutes.</p>
                        )}
                        <p>OrderId: {orderid} </p>
                        <h1 className="font-light text-white text-3xl"> Thank you for your order! </h1>
                        <br />



                        {orderData && orderData.status === "paid" ? (
                            <h5>

                                Your files are ready for download!
                            </h5>

                        ) : (
                            <Skeleton className="ml-5" baseColor="#171717" highlightColor="#1a1a1a" count={1} width={270} height={20} />
                        )}

                        {orderData && orderData.status === "paid" ? (
                            <>
                            <Button className="cartbtn mt-4 button md:w-[25vw] w-full fixed flex gap-1 justify-center items-center " onClick={downloadOrder} >
                                Download files <BiDownload />
                            </Button>
                            <div className="mt-4">
                                <p className="mb-10">Click  <a href={dwLink}> here </a> if download doesnt start. </p>
                                <p className="mt-20 mb-10"><span className="text-3xl text-white">Wait before you leave! </span> <br /> As a thank you for ordering - Use coupon: "{orderid.substring(0, 4)}" to <span className="highlight">get 10% off your next beat. </span> </p>
                                <a href="/beats" onClick={() => handelRedeem()}><p className="flex text-white items-center gap-3"><BsArrowLeft /> Redeem & Go to the library</p></a>
                                <p className="mt-2 mb-10 flex items-center gap-2"><LuAlertTriangle /> After redemption, the coupon will expire within 48 hours.</p>

                            </div>
                            </>
                        ) : (
                            <Button disabled={true} className="cartbtn mt-4 md:w-[200px] w-full fixed flex gap-1 justify-center items-center " >
                                Order is being processed
                            </Button>
                        )}


                    </div>
                    <div className="h-[100px] w-full">

                    </div>
                    <div>
                        <p>If you have any questions, don't hesitate to reach out to us at: info@intialsounds.com</p>
                        <p className="text-xs">The link will remain valid for three months; misuse will result in its removal.</p>
                    </div>
                </div>
            </Container>
        </div>
    );

}