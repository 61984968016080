import React from 'react';

const PaymentIcon = ({ iconName, folderName = '' }) => {
    const iconPath = `${process.env.PUBLIC_URL}/IconsSource${folderName ? `/${folderName}` : ''}/${iconName}.svg`;

    return (
        <img 
            src={iconPath} 
            alt={`${iconName} icon`} 
            style={{ width: 'auto', height: 'auto' }} 
        />
    );
};

export default PaymentIcon;
