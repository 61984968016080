import React, { useRef, useState, useEffect } from 'react';
import { BsPlayCircle, BsPauseCircle, BsFillCartFill } from 'react-icons/bs'
import { BiVolumeFull, BiVolumeLow, BiVolume, BiCart, BiSolidCart } from 'react-icons/bi'
import { Button, Slider } from 'rsuite';
const AudioPlayer = ({ src, trackInfo, isPlaying, setIsPlaying, onAddToCart }) => {
  const audioRef = useRef();
  const [isMuted, setIsMuted] = useState(false); // Add this line
  const audioSilderRef = useRef();
  const [progress, setProgress] = useState(0);
  const progressBarRef = useRef();
  const circleRef = useRef();
  const [isDragging, setIsDragging] = useState(false);
  const [objectUrl, setObjectUrl] = useState(null);
  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };
  const [volume, setVolume] = useState(1);

  const updateProgress = () => {
    if (!isDragging) {
      setProgress((audioRef.current.currentTime / audioRef.current.duration) * 100);
    }
  };
  const handleAddToCart = (track) => {
    onAddToCart(track);
  };
  const skipTo = (e) => {
    if (audioRef.current.duration) {
      const rect = progressBarRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const clickedValue = x * audioRef.current.duration / rect.width;
      audioRef.current.currentTime = clickedValue;
      setProgress((clickedValue / audioRef.current.duration) * 100);
    }
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
    window.addEventListener('mouseup', handleMouseUp);
    window.addEventListener('mousemove', handleMouseMove);
  };

  const handleMouseUp = (e) => {
    if (isDragging) {
      skipTo(e);
    }
    setIsDragging(false);
    window.removeEventListener('mouseup', handleMouseUp);
    window.removeEventListener('mousemove', handleMouseMove);
  };

  const handleMouseMove = (e) => {
    if (isDragging && audioRef.current.duration) {
      const rect = progressBarRef.current.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const clickedValue = x * audioRef.current.duration / rect.width;
      setProgress((clickedValue / audioRef.current.duration) * 100);
    }
  };

  const handleMute = (v) => {
    setIsMuted(!isMuted);
    if (audioRef.current) {
      audioRef.current.volume = isMuted ? 1 : 0;
      setVolume(isMuted ? 1 : 0);
      audioSilderRef.value = isMuted ? 1 : 0;
    }
  };
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.pause();

      fetch(src)
        .then(response => response.blob())
        .then(blob => {
          const url = URL.createObjectURL(blob);
          setObjectUrl(url);
          audioRef.current.src = url;
          if (!audioRef.current.paused && !isPlaying) {
            audioRef.current.play();
          }
        });

      return () => URL.revokeObjectURL(objectUrl); // clean up object URL
    }
  }, [src]);

  useEffect(() => {
    if (isPlaying) {
      const playPromise = audioRef.current.play();
      if (playPromise !== undefined) {
        playPromise.then(_ => {
          // Automatic playback started!
        })
        .catch(error => {
          // Auto-play was prevented
        });
      }
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);
  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener('ended', () => {
        setIsPlaying(false);
        setProgress(0);
      });
      
      // Add mousedown event listener to the circle
      if (circleRef.current) {
        circleRef.current.addEventListener('mousedown', handleMouseDown);
      }
    }
    
    return () => { // Clean up event listeners
      if (circleRef.current) {
        circleRef.current.removeEventListener('mousedown', handleMouseDown);
      }
      
      window.removeEventListener('mouseup', handleMouseUp);
      window.removeEventListener('mousemove', handleMouseMove);
    };
    
  }, []);

  const handleVolumeChange = (value) => {
  if (audioRef.current) {
      setVolume(value);
      audioRef.current.volume = value;
      audioSilderRef.value = value;
    }
  };

    // Add this function
  const handleClick = (e) => {
    if (!isDragging) {
        skipTo(e);
    }
  };

  // Add click event listener to the progress bar
  useEffect(() => {
    if (progressBarRef.current) {
        progressBarRef.current.addEventListener('click', handleClick);
    }
    
    return () => { // Clean up event listeners
        if (progressBarRef.current) {
            progressBarRef.current.removeEventListener('click', handleClick);
        }
    };
  }, []);
  return (
    <div className="audio-player">
      <audio ref={audioRef} onTimeUpdate={updateProgress} autoPlay /> {/* Add autoPlay attribute */}
      <div className="controls">
        <div ref={progressBarRef} className="progress-bar" onMouseDown={handleMouseDown}>
          <div className="progress" style={{ width: `${progress}%` }} />
          <div ref={circleRef} className="circle" style={{ left: `${progress}%` }} />
        </div>
        <div className='data flex justify-between items-center'>
          <span className='track-title flex items-center justify-start gap-5'>
            <div className='track-meta'>
              <span className='author'>  {trackInfo.author} </span> <br></br>
              <span className='title'> {trackInfo.title} </span> <br></br>
               <span className='bpm'> {trackInfo.bpm} BPM </span> 
               <span className='key price '> {trackInfo.songkey}  </span> 
            </div>
            <Button className='cartbtn button'> <div className='flex justify-center items-center gap-2 ' onClick={() => handleAddToCart(trackInfo)}><BiSolidCart className='md:ml-3 ml-1 icoCart' />  <span className='md:block hidden'>{trackInfo.currency + ' ' + trackInfo.price}</span></div> </Button>
          </span>

          <button  className='play flex justify-center items-center' onClick={togglePlayPause}>{isPlaying ? <BsPauseCircle /> : <BsPlayCircle /> }</button>
          <div className='volume-control min-w-[200px] gap-4 flex justify-start items-center'>
          <div className='volume'>
            {volume > 0.5 ? <BiVolumeFull  onClick={handleMute} /> : volume > 0 ? <BiVolumeLow  onClick={handleMute} /> : <BiVolume   onClick={handleMute}/>}
          </div>
          <Slider
            ref={audioSilderRef}
            min={0}
            max={1}
            step={0.01}
            value={volume}
            onChange={value => handleVolumeChange(value)}
            progress
            className='w-full volume-slider'
            tooltip={false}
          />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
