import { Container, Content } from "rsuite";
import "./css/Home.min.css";
import "./css/main.min.css";
import ScrollingText from "./ScrollingText";

import { Tracks } from "./Tracks";
import WaveformPlayer from "./audioplayer";
import { useState } from "react";
import { SFaq } from "./SeoFaq";
import { MdError } from "react-icons/md";
import { TbMusicExclamation } from "react-icons/tb";
 

export const Home = () => {
  return (
    <div className="page">
        {/* <VideoPlayer></VideoPlayer> */}
        <div className="mt-80 text-center">
          <div className="flex px-8 flex-col items-center justify-center gap-3">
            <span className="err"><TbMusicExclamation /> </span>
            <span>We are currently under maintenance | Check back soon... </span>
          </div>
        </div>
        
    </div>
  );
};
