import logo from "./logo.svg";
import "./App.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Home } from "./Home";
import { Header } from "./Header";
import "./css/dwportal.min.css";
import 'rsuite/dist/rsuite.min.css'; // Default theme
import { ViewTrack } from "./view-track";
import { WaitingList } from "./pages/waiting-list";
import { useEffect, useState } from "react";
import { AudioPlayerContext } from './AudioPlayerContext';
import { Footer } from "./footer";
import { useLocation } from 'react-router-dom';
import { Profile } from "./pages/profile";
import { Container } from "rsuite";
import { DwPortal } from "./dwportal";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Tracks } from "./Tracks";
import CartManager from "./CartManager";
import axios from "axios";
import { Checkout } from "./checkout";
import { Orders } from "./Orders";

// Initialize AOS
AOS.init();


export default function App() {
  const [audioPlayer, setAudioPlayer] = useState(null);
  const { cartIsOpen, setCartIsOpen, cartItems, setCartItems } = CartManager();
    useEffect(() => {
      // Retrieve cartItems from session storage
      const storedCartItems = sessionStorage.getItem('cartItems');
      if (storedCartItems) {
          setCartItems(JSON.parse(storedCartItems));
      }
      
      // Uncomment the following lines to retrieve cartItems from cookies
      // const cookieValue = document.cookie.replace(/(?:(?:^|.*;\s*)cartItems\s*\=\s*([^;]*).*$)|^.*$/, "$1");
      // if (cookieValue) {
      //     setCartItems(JSON.parse(cookieValue));
      // }
    }, []); // Empty dependency array ensures the effect runs only once after the initial render

    const addToCart = (track) => {
      // Check if the track's ID is already in the cart
      const isAlreadyInCart = cartItems.some(item => item.id === track.id);
      setCartIsOpen(true);
  
      if (isAlreadyInCart) {
          console.log("Track is already in the cart");
  
          // Update the version of the track if it exists
          const updatedCartItems = cartItems.map(item => {
              if (item.id === track.id) {
                  return { ...item, version: track.version }; // Update to the wished version
              }
              return item; // Return the item unchanged
          });
  
          setCartItems(updatedCartItems); // Update the cart state with the modified items
      } else {
          console.log("Track is not in the cart, adding new item");
          
          // If the item doesn't exist, you can add the new item to the cart
          // Proceed with adding the track to the cart
        const updatedCartItems = [...cartItems, track];
        setCartItems(updatedCartItems); // Add the track to the cartItems array
        
        console.log("Item added to cart:", track);

        // Store updated cartItems in session storage
        sessionStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
      
      }
 

    
    // setTimeout(() => {
    //     setCartIsOpen(false);
    // }, 2000);
};  

  return (
    <>
      <AudioPlayerContext.Provider value={{ audioPlayer, setAudioPlayer }}>
        <Header setCartItems={setCartItems} cartItems={cartItems} cartOpen={cartIsOpen} setCartOpen={setCartIsOpen}></Header>
        <Router>
       <Container className="md:min-h-[70vh] min-h-[50vh] "> 
        
        <Routes>
            <Route path="/" element={<> <Home/> 
              {/* <Tracks  onAddToCart={addToCart} currency={"€"} /> */}
              </>} />
            <Route path="/beats" element={<Tracks onAddToCart={addToCart} currency={"€"} />} />
            <Route path="/orders/:orderid" element={<Orders />} />
            <Route path="/checkout" element={<Checkout setCartItems={setCartItems} cartItems={cartItems}  />} />
            <Route path="/download-your-files/:dwID" element={<DwPortal />} />
            <Route path="/view-track/:title/:id" element={<ViewTrack />} />
            {/* <Route path="/waiting-list" element={<WaitingList />} /> */}
            <Route path="/profile/:account" element={<Home />} />
        </Routes>
        </Container>

          <WrapperFooter />
        </Router>
        {audioPlayer && (
          <>
            <div id="testdiv" className="w-full h-[600px]"></div>
          </>
        )}
        {audioPlayer}
      </AudioPlayerContext.Provider>
    </>
  );
}

function WrapperFooter() {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return <Footer mailing={isHomePage} />;
}