import { Button, Container, Progress } from "rsuite";
import $, { ajax } from "jquery"; 
import { useParams } from "react-router-dom";
import { useState } from "react";
export const DwPortal = () => {
    const { dwID } = useParams(); // get the dwID from the URL
    const [progress, setProgress] = useState(0);
    const [downloadInfo, setDownloadInfo] = useState("");
    const [complete, setDownloadComplete] = useState(false);
    function fetch_files() {
        setTimeout(() => {
            var formData = new FormData();
             formData.append('dw_pack', dwID);

            $.ajax({
                url: 'https://www.api.initialsounds.com/index.php ',
                  

                // url: 'http://localhost/inapi/',
                type: 'POST',
                data: formData,
                dataType: 'binary',
                processData: false,  // tell jQuery not to process the data
                contentType: false,  // tell jQuery not to set contentType
                xhrFields: {
                    responseType: 'blob'
                },
                xhr: function() {
                    var xhr = new window.XMLHttpRequest();
                    xhr.addEventListener('progress', function(evt) {
                        if (evt.lengthComputable) {
                            var percentComplete = evt.loaded / evt.total;
                            percentComplete = parseInt(percentComplete * 100);

                            // Update progress state
                            setProgress(percentComplete);

                            // Update download info
                            if (percentComplete === 100) {
                                setDownloadInfo("Download complete");
                                setDownloadComplete(true);
                                $('#download-files').removeClass('dw_disabled');
                                $('#download-files').prop('disabled', false);
                            } else {
                                setDownloadInfo("Download progress: " + percentComplete + "%");
                            }
                        }
                    }, false);
                    return xhr;
                },
                success: function(response, status, xhr) {
                     // Generate a random MD5 hash and use the first 10 characters as the filename
                    var filename = "download_" + (Math.random().toString(36)+'00000000000000000').slice(2, 12) + '_initialsounds.com.zip';

                    const url = window.URL.createObjectURL(response);
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                },
                error: function(xhr, status, err) {
                    console.error('An error occurred: ', err);
                    setDownloadInfo("An error occurred: " + err);
                }
            });
        }, 3000);
    }
    
    
    function dw_startdownload() {
      setTimeout(() => {
        setDownloadComplete(false);

        $('#download-files').addClass('dw_disabled');
        $('#download-files').prop('disabled', true);
        setDownloadInfo("download is starting..");
 
        fetch_files();
      }, 100);
    }
    

    return(
        <>
            <Container className="flex justify-center items-center">
                <div className="dwcard shd my-40 flex flex-col justify-between md:max-w-[50%] "  data-aos="fade-up">
                    <div>
                        <h3 className="text-white font-light text-center mb-2"> Download your files </h3>

                        <p className="text-start">Thank you for your order <br /> <br /> Your files will be stored on our server for a period of 2 months. If you are unable to download the files after this period, please contact us.</p>
                    </div>

                    <div>
                        {/* <p className="text-white">Your files: </p>
                        <div id="files" className="flex flex-col justify-between">
                        <span> /track 01.zip</span>
                        <span> /track 01.zip</span>
                        <span> /track 01.zip</span>
                    
                        </div> */}

                        <p className="text-white">order number: {dwID} </p>
                        <br></br>

                    </div>
                    <div className="flex justify-center items-center w-full">
                    { progress > 1 && !complete && (
                <Progress percent={progress} className="p-0 md:max-w-[50%] max-w-[100%]" showInfo={false} />
            )}
                    </div>
                    
                    <div className="flex flex-col justify-center items-center">
                        <div id="download-info" className="mb-2 text-yellow-300 text-center">{downloadInfo}</div>

                        <Button className="btn-m-0 btn cartbtn min-w-[90%]" id="download-files"  onClick={dw_startdownload}>
                            Download Files 
                        </Button>
                    </div>
                </div>
            </Container>
        </>
    );
}