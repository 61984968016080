import { BiLogoFacebook, BiLogoInstagram, BiLogoTiktok, BiLogoYoutube } from "react-icons/bi";
import { Container, Input } from "rsuite";
import PaymentIcon from "./PaymentIcon";
 
export const Footer = ({ mailing }) => {
    return (
        <div className="footer">
            {/* { mailing && (
                <div className="mail">
                    <Container className='r max-w-[1200px] py-20'>
                    <div className="flex justify-center items-center">
              
                        <div className="md:w-1/2 w-[85%]">
                            <h3 className="text-xl">Are you a producer?</h3> 
                            <span> Start now selling your creations online! Sign up for the waiting list. <a href="/waiting-list">Why the wait?</a> </span>
                            <Input placeholder="Enter your email adress.."  className="my-5"/>
                        </div>
                    </div>
                    
                    </Container>
                            
                </div>
                )} */}
            <div className="p-5" >
                <Container className='r max-w-[1200px] md:text-start text-center'>
                    <div className="md:flex md:flex-nowrap flex-wrap   justify-center ">
                        <div className="md:w-1/3 m-auto  my-10">
                            <h3 class="font-bold text-2xl text-white">INITIAL SOUNDS</h3>
                            <p>Find your sound.</p>
                        </div>
                        <div className="w-1/3 flex justify-center items-start md:my-0 my-5 m-auto">
                            <div>
                                <h5 class="font-bold text-1xl text-white my-2">Links</h5>
                                <div className="footer-links">
                                    <a className="footer-link mt-2 flex md:justify-start justify-center" href="/">
                                        Home
                                    </a>
                                    <a className="footer-link mt-2 flex md:justify-start justify-center" href="/#">
                                        Beat Library
                                    </a>
                                    <a className="footer-link mt-2 flex md:justify-start justify-center" href="/#">
                                        Downloads
                                    </a>
                                    <a className="footer-link mt-2 flex md:justify-start justify-center" href="/#">
                                        Selling as producer
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="w-1/3 flex justify-center items-start md:my-0 my-5 m-auto ">
                            <div className="md:w-1/2 ">
                                <h5 class="font-bold text-1xl text-white my-2">Contact</h5>
                                <p>info@initialsounds.com</p>
                                <div className="flex flex-wrap">
                                    <a href="/terms-of-service" className="w-full text-gray-500">Terms & Services</a>
                                    <a href="/privacy-policy" className="w-full text-gray-500">Privacy Policy</a>
                                </div>
                                <div className="flex flex-col mt-4 min-w-[250px] items-start justify-start gap-2">
                                    <p className="mr-2">We accept: </p>
                                    <div className="flex gap-3 items-center">
                                        <div className="w-full h-full  min-w-[80px] max-w-[120px]" id="paypal">
                                            <PaymentIcon iconName={'paypal_transparent'} />
                                        </div>
                                        <div className="w-full h-full  min-w-[60px] max-w-[80px]" id="ideal">
                                            <PaymentIcon iconName={'ideallogo'} />
                                        </div>
                                        <div className="w-full h-full  min-w-[60px] max-w-[80px]" id="bancontact">
                                            <PaymentIcon iconName={'Bancontact'} />
                                        </div>
                                    </div>
{/*                                     
                                    <div className="w-full ml-2 h-full min-w-[50px]  max-w-[60px]" id="ideal">
                                        <img src="/paymentProviders/ideal/logo.svg"></img>
                                    </div> */}
                                    {/* <div className="w-full h-full min-w-[80px]" id="bancontact">
                                      <img src="/paymentProviders/bancontact/logo.svg"></img>
                                    </div> */}
                                    {/* <div className="w-full h-full" id="bitcoin">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" data-name="Bitcoin"><path fill="#f7931a" d="M63.04 39.741c-4.275 17.143-21.638 27.576-38.783 23.301C7.12 58.768-3.313 41.404.962 24.262 5.234 7.117 22.597-3.317 39.737.957c17.144 4.274 27.576 21.64 23.302 38.784z"></path><path fill="#FFF" d="M46.11 27.441c.636-4.258-2.606-6.547-7.039-8.074l1.438-5.768-3.51-.875-1.4 5.616c-.924-.23-1.872-.447-2.814-.662l1.41-5.653-3.509-.875-1.439 5.766c-.764-.174-1.514-.346-2.242-.527l.004-.018-4.842-1.209-.934 3.75s2.605.597 2.55.634c1.422.355 1.68 1.296 1.636 2.042l-1.638 6.571c.098.025.225.061.365.117l-.37-.092-2.297 9.205c-.174.432-.615 1.08-1.609.834.035.051-2.552-.637-2.552-.637l-1.743 4.019 4.57 1.139c.85.213 1.682.436 2.502.646l-1.453 5.834 3.507.875 1.44-5.772c.957.26 1.887.5 2.797.726l-1.434 5.745 3.511.875 1.453-5.823c5.987 1.133 10.49.676 12.384-4.739 1.527-4.36-.076-6.875-3.226-8.515 2.294-.529 4.022-2.038 4.483-5.155zM38.086 38.69c-1.085 4.36-8.426 2.003-10.806 1.412l1.928-7.729c2.38.594 10.012 1.77 8.878 6.317zm1.086-11.312c-.99 3.966-7.1 1.951-9.082 1.457l1.748-7.01c1.982.494 8.365 1.416 7.334 5.553z"></path></svg>
                                    </div>
                                  */}
                                    
                                </div>
                                {/* <h5 class="font-bold text-1xl text-white my-2">Start selling</h5>
                            <div className="footer-links">
                                <a className="footer-link flex md:justify-start justify-center">
                                    Sell your beats
                                </a>
                                 
                                <a className="footer-link flex md:justify-start justify-center">
                                    Sell your songs
                                </a>
                                <a className="footer-link flex md:justify-start justify-center">
                                    Sell your sample packs
                                </a>
                                <a className="footer-link flex md:justify-start justify-center">
                                    Become a ghost producer
                                </a>
                            </div> */}

                            </div>
                        </div>
                    </div>

                    <div className='links mt-10 py-2 '>
                        <span className="md:block hidden mr-2">KvK 82743037 | Powered by <a href="https://www.zwlsoftware.com">ZWL Software</a></span>

                        <div className='social-link flex items-center   gap-1'>

                            <a href="https://www.instagram.com/initial.sounds" className='flex color justify-center items-center' target="_blank" rel="noopener noreferrer">
                                <BiLogoInstagram></BiLogoInstagram>
                            </a>
                            <a href="https://www.tiktok.com/@initialsounds" className='flex color justify-center items-center' target="_blank" rel="noopener noreferrer">
                                <BiLogoTiktok></BiLogoTiktok>
                            </a>
                            <a href="https://www.tiktok.com/@initialsounds" className='flex color justify-center items-center' target="_blank" rel="noopener noreferrer">
                                <BiLogoYoutube></BiLogoYoutube>
                            </a>
                        </div>
                    </div>
                    <span className="md:hidden mt-1 block w-full">KvK: 82743037 | Powered by <a href="zwlsoftware.com">ZWL Software</a></span>

                </Container>
            </div>
        </div>
    );
}